import dynamic from 'next/dynamic';

export default {
    ArticleListPage: dynamic(() => import('./ArticleListPage')),
    ArticlePage: dynamic(() => import('./ArticlePage')),
    BasePage: dynamic(() => import('./BasePage')),
    ElearningListPage: dynamic(() => import('./ElearningListPage')),
    EmbedPage: dynamic(() => import('./EmbedPage'), { ssr: false }),
    EventListPage: dynamic(() => import('./EventListPage')),
    EventPage: dynamic(() => import('./EventPage')),
    HomePage: dynamic(() => import('./HomePage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    PartnerListPage: dynamic(() => import('./PartnerListPage')),
    SearchPage: dynamic(() => import('./SearchPage')),
    UspPage: dynamic(() => import('./UspPage')),
};
